import CryptoJS from 'crypto-js';

const SECRET_KEY = 'v5nxGYeFgH'; // 鉴权密钥
const SIGN_NAME = 'buge_sign'; // 签名参数
const UID = '0'; // 用户ID

// 计算字符串的 SHA256 哈希值
function getSha256(text) {
  return CryptoJS.SHA256(text).toString(CryptoJS.enc.Hex);
}

// 生成带签名的 URL
export default function getSignedUrl(url, secretKey = SECRET_KEY, signName = SIGN_NAME, uid = UID) {
  const ts = Math.floor(Date.now() / 1000); // 当前时间戳
  // 解析输入的 URL
  const urlObj = new URL(url);
  const path = urlObj.pathname;

  // 生成一个 10 位的随机字符串
  const randstr = Math.random().toString(36).substring(2, 12);

  // 组合字符串以计算哈希值：path、时间戳、随机字符串、用户ID和密钥
  const text = `${path}-${ts}-${randstr}-${uid}-${secretKey}`;

  // 计算字符串的 SHA256 哈希值
  const hash = getSha256(text);

  // 生成鉴权参数
  const authArg = `${ts}-${randstr}-${uid}-${hash}`;

  // 将鉴权参数添加到 URL 查询参数中
  urlObj.searchParams.append(signName, authArg);

  return urlObj.toString();
}
