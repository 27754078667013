
import { defineComponent, ref, reactive, toRefs, onMounted, onBeforeUnmount } from 'vue';
import bus from 'vue3-eventbus';
import getSignedUrl from '../utils/getSignedUrl.js';
export default defineComponent({
  setup() {
    const isAppshow = ref(false);
    const isAndShow = ref(false);
    const data = reactive({
      pictureList: []
    });
    //展示二维码
    const showEwm = (e: number) => {
      if (e == 1) {
        isAppshow.value = true;
      } else if (e == 2) {
        isAndShow.value = true;
      }
    };
    //关闭二维码
    const closeEwm = (e: number) => {
      if (e == 1) {
        isAppshow.value = false;
      } else if (e == 2) {
        isAndShow.value = false;
      }
    };
    onMounted(() => {
      bus.on('handleImg', (item) => {
        data.pictureList = item;
      });
    });
    onBeforeUnmount(() => {
      bus.off('handleImg');
    });
    return {
      showEwm,
      closeEwm,
      ...toRefs(data),
      isAndShow,
      isAppshow,
      getSignedUrl
    };
  }
});
